import React from "react";
import { BlogSection } from "../../components/Home/BlogSection/BlogSection";
import { TherapySectionLinks } from "../../components/Services/Therapy/components/TherapySectionLinks/TherapySectionLinks";
import { IntroSection } from "../../components/Services/Therapy/IntroSection/IntroSection";
import { ShipStorySection } from "../../components/Services/Therapy/ShipStorySection/ShipStorySection";
import { TherapyServicesSection } from "../../components/Services/Therapy/TherapyServicesSections/TherapyServicesSections";
import { WhatCanYouExpectSection } from "../../components/Services/Therapy/WhatCanYouExpectSection/WhatCanYouExpectSection";
import { TopHeader } from "../../components/Shared/Layout/components/TopHeader/TopHeader";
import Layout from "../../components/Shared/Layout/Layout";

export interface TherapyPageProps {
  className?: string;
}

const TherapyPage = () => (
  <Layout>
    <TopHeader title="Služby / Brief Terapia" />
    <ShipStorySection />
    <WhatCanYouExpectSection />
    <TherapySectionLinks />
    <TherapyServicesSection />
    <BlogSection />
  </Layout>
);

export default TherapyPage;
