import { Container } from "@material-ui/core";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ButtonAndShortTextProps } from "../../../../Shared/Button/ButtonAndShortText/ButtonAndShortText";
import { Section } from "../../../../Shared/Layout/components/Section/Section";
import { SectionLinks } from "../../../shared/SectionLinks/SectionLinks";
import { useTherapyServices } from "../../TherapyServicesSections/hooks/useTherapyServices";

export interface TherapySectionLinksProps {
  className?: string;
}

const TherapySectionLinksInner = (props: TherapySectionLinksProps) => {
  const links = useMemo(
    () =>
      Object.values(useTherapyServices()).map(
        (service): ButtonAndShortTextProps => ({
          buttonTitle: service.title,
          buttonUrl: `#${service.title}`,
          shortText: service.shortText,
          buttonType: "primary"
        })
      ),
    []
  );
  return (
    <Section className={props.className}>
      <Container>
        <SectionLinks links={links} />
      </Container>
    </Section>
  );
};

export const TherapySectionLinks = styled(TherapySectionLinksInner)`
  ${SectionLinks} {
    justify-content: space-evenly;
  }
`;
