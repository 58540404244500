import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextSection } from "../../../Shared/Layout/components/TextSection/TextSection";
import image from "./ship.png";

export interface ShipStorySectionProps {
  className?: string;
}

const ShipStorySectionInner = (props: ShipStorySectionProps) => {
  const { t } = useTranslation("translations");
  return (
    <div className={props.className}>
      <img src={image} />
      <TextSection
        title={t("therapyPage.shipStory.title")}
        description={t("therapyPage.shipStory.text")}
      />
    </div>
  );
};

export const ShipStorySection = styled(ShipStorySectionInner)`
  img {
    margin: auto;
    display: flex;
    height: 150px;
    margin-top: 70px;
  }
`;
