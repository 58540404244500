import { graphql, useStaticQuery } from "gatsby";

export const ServiceFragment = graphql`
  fragment ServiceFragment on MarkdownRemark {
    html
    frontmatter {
      image {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title

      referenceTitle
      referenceText
      shortText

      referenceImage {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export const useTherapyServices = () => {
  const { strapiTherapy: { service } } = useStaticQuery(graphql`
    query useTherapyServices {
      strapiTherapy {
        service {

          title
          content: text
          shortText
          image {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          reference {
            name: title
            reference: text
            image {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }

        }
      }
    }
  `);

  return service;
};
